// import './App.css';

// // 여기는 연결해 주는 곳!! route 에서 페이지 등록을 해놔야 띄울수있다.
// // 함수에 매개변수도 타입을 지정하고 리턴값이 있으면 리턴값도 타입 지정해 줘야 함
// function App() {
//   const a = 2;
//   const b = 3;
//   const c = 3;

//   return (
//     <div className="App">
//       {/* <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header> */}
//     </div>
//   );
// }

// export default App;

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import styles from './App.module.css';
import { Login, GetList, ErrorPage, Ganzi } from './pages';
// import { useState } from 'react';

function App() {
  // const [isFinishedAnimation, setIsFinishedAnimation] = useState(false);

  // const setFinishedStatus = () => setIsFinishedAnimation(true);

  return (
    <div className={`App ${styles.background}`}>
      <BrowserRouter>
        <Routes>
          {/* <Route
            element={
              <Home
                isFinishedAnimation={isFinishedAnimation}
                setFinishedStatus={setFinishedStatus}
              />
            }
            path="/"
          />
          <Route element={<SettingDiagnosis />} path="/diagnosis/types" />
          <Route
            element={<SurveyDiagnosis />}
            path="/diagnosis/types/survey/:type"
          />

          <Route
            element={<DeviceStepOne />}
            path="/diagnosis/types/:kind/:type/step-one/:progress"
          />
          <Route
            element={<DeviceStepTwo />}
            path="/diagnosis/types/:kind/:type/step-two/:progress"
          />
          <Route
            element={<DeviceDiagnosis />}
            path="/diagnosis/types/:kind/:type/:progress"
          />

          <Route
            element={<DiagnosisHistory />}
            path="/diagnosis/types/:kind/:type/history"
          /> */}

          {/* 기본 경로('/')에 접속하면 '/login'으로 리다이렉트 */}
          <Route path="/" element={<Navigate replace to="/login" />} />

          {/* 로그인 & 회원가입 */}
          <Route element={<Login />} path="/login" />
          <Route element={<GetList />} path="/getlist/:group" />
          <Route
            element={<Ganzi />}
            path="/ganzi/:groupInfo/:regDt/:lang/:type/:skincode"
          />
          {/* <Route element={<SignUp />} path="/sign-up" /> */}
          {/* "*" 경로는 다른 모든 정의되지 않은 경로를 의미 */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
