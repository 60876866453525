import { Tabs, rem } from '@mantine/core';
import {
  IconDeviceIpadHorizontalCheck,
  IconMoodCheck,
  IconBuildingFactory2,
} from '@tabler/icons-react';

import { useTranslation } from 'react-i18next';
import i18n from '../../../assets/locales/i18n';

interface RecoreTypeTabsProps {
  recordState: string | null;
  setRecordState: (value: string | null) => void;
}

const RecordTypeTabs = ({
  recordState,
  setRecordState,
}: RecoreTypeTabsProps) => {
  const iconStyle = { width: rem(12), height: rem(12) };
  console.log(recordState);
  const { t } = useTranslation();

  return (
    <Tabs
      value={recordState}
      onChange={setRecordState}
      color="indigo"
      variant="outline"
      defaultValue="diagnosis"
    >
      <Tabs.List>
        <Tabs.Tab
          value="diagnosis"
          fz={'lg'}
          leftSection={<IconMoodCheck style={iconStyle} />}
        >
          {t(`recordType.diagnosis`)}
        </Tabs.Tab>
        <Tabs.Tab
          value="produce"
          fz={'lg'}
          leftSection={<IconBuildingFactory2 style={iconStyle} />}
        >
          {t(`recordType.produce`)}
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};

export default RecordTypeTabs;
