import { useEffect, useRef, useState } from 'react';
import {
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem,
  keys,
} from '@mantine/core';
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  // IconkeywordState,
  IconLink,
  IconPrinter,
} from '@tabler/icons-react';
import classes from './GetListTable.module.css';
import {
  ProduceScalpRowData,
  ProduceSkinRowData,
} from '../../types/ProduceRowData';
import { useDisclosure } from '@mantine/hooks';
import { NotFoundData } from '../nodata/NotFoundData';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { getSkinRecipeApi, getSkinScoreApi } from '../../utils/getGanziApi';
import { useNavigate } from 'react-router-dom';

interface DiagnosisRowData {
  age: number;
  base: string;
  concept1: string;
  concept2: string;
  concept3?: string;
  concept5?: string;
  gender: string;
  managerAccount: string;
  managerGroup: string;
  managerName: string;
  nickname: string;
  oil: string;
  phone: string;
  preSurveyAnswers: string;
  prodType: string;
  regDt: string;
  skinCode: string;
  skinType: string;
  [key: string]: any;
}

// interface ProduceRowData {
//   seq: number;
//   prodType: string;
//   name: string;
//   gender: string;
//   age: number;
//   base: string;
//   oil: string;
//   skinconcept1: string;
//   skinconcept2: string;
//   skintrouble1: string | null;
//   skintrouble2: string | null;
//   skinType: string;
//   skinCode: string;
//   createDt: string;
//   qrCodeUrl: string | null;
//   managerGroup: string;
//   managerAccount: string;
//   managerName: string;
//   eqpId: string;
// }

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <Table.Th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}

// function filterData(
//   data: (DiagnosisRowData | ProduceSkinRowData | ProduceScalpRowData)[],
//   keywordState: string
// ) {
//   const query = keywordState.toLowerCase().trim();
//   return data.filter((item) =>
//     keys(data[0]).some((key) =>
//       (item[key] as string).toLowerCase().includes(query)
//     )
//   );
// }

// 모든 항목의 값을 문자열로 처리하여서 검색에 사용되게 만들기
function filterData(
  data: (DiagnosisRowData | ProduceSkinRowData | ProduceScalpRowData)[],
  keywordState: string
) {
  const query = keywordState.toLowerCase().trim();
  return data.filter((item) =>
    keys(item).some((key) =>
      String(item[key] ?? '')
        .toLowerCase()
        .includes(query)
    )
  );
}

interface GetListTableProps {
  header: { title: string }[];
  rowData: (DiagnosisRowData | ProduceSkinRowData | ProduceScalpRowData)[];
  // tableRows: React.ReactNode;
  keywordState: string;
  recordState: string | null;
  diagTypeState: string;
  languageState: string | null;
  period: string[];
  record: string | null;
  type: string;
  groupInfo: string;
}
export function GetListTable({
  header,
  rowData,
  // tableRows,
  keywordState,
  recordState,
  diagTypeState,
  languageState,
  period,
  record,
  type,
  groupInfo,
}: GetListTableProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState(rowData);
  const [sortBy, setSortBy] = useState<
    keyof (DiagnosisRowData | ProduceSkinRowData | ProduceScalpRowData) | null
  >(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [printOpend, printHandler] = useDisclosure(false);
  const [filteringData, setFilteringData] = useState(
    rowData as (DiagnosisRowData | ProduceSkinRowData | ProduceScalpRowData)[]
  );

  function sortData(
    data: (DiagnosisRowData | ProduceSkinRowData | ProduceScalpRowData)[],
    payload: {
      sortBy:
        | keyof (DiagnosisRowData | ProduceSkinRowData | ProduceScalpRowData)
        | null;
      reversed: boolean;
      keywordState: string;
    }
  ): (DiagnosisRowData | ProduceSkinRowData | ProduceScalpRowData)[] {
    const { sortBy, reversed, keywordState } = payload;

    // 먼저, 검색어로 필터링
    const filteredData = filterData(data, keywordState);

    // 정렬 기준이 있는 경우에만 정렬 수행
    if (sortBy) {
      filteredData.sort((a, b) => {
        const valueA = a[sortBy];
        const valueB = b[sortBy];
        let comparison = 0;

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          comparison = valueA - valueB;
        } else {
          const stringA = String(valueA);
          const stringB = String(valueB);
          comparison = stringA.localeCompare(stringB);
        }

        return reversed ? -comparison : comparison;
      });
    }

    console.log(filteredData);
    setFilteringData(filteredData);

    return filteredData; // 필터링 및 정렬된 데이터 반환
  }

  // sortedData 상태가 변경될 때마다 실행
  useEffect(() => {
    console.log(sortedData); // 여기에서 sortedData를 로그로 출력
  }, [sortedData]); // sortedData가 변경될 때마다 이 useEffect를 실행

  useEffect(() => {
    console.log(sortBy);
    console.log(filteringData);
    console.log(rowData);
  }, []);

  // 검색어가 비어있으면 rowData, 검색어가 입력되면 filteringData
  useEffect(() => {
    if (keywordState.trim() === '') {
      setFilteringData(rowData);
    } else {
      const filterd = filterData(rowData, keywordState); // 검색어가 있을 때 필터링된 데이터 사용
      setFilteringData(filterd);
    }
  }, [keywordState, rowData]);

  const setSorting = (
    field: keyof (DiagnosisRowData | ProduceSkinRowData | ProduceScalpRowData)
  ) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    const newSortedData = setSortedData(
      sortData(rowData, { sortBy: field, reversed, keywordState })
    );
    console.log(newSortedData);
  };

  // const handlekeywordStateChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { value } = event.currentTarget; // 사용자가 입력한 값

  //   setkeywordState(value); // 검색 상태 업데이트
  //   setSortedData(
  //     // rowData, sortBy, reverseSortDirection, 그리고 사용자 입력을 기반으로 sortData 함수를 호출하여 데이터를 정렬 및 필터링
  //     sortData(rowData, {
  //       sortBy,
  //       reversed: reverseSortDirection,
  //       keywordState: value,
  //     })
  //   );

  //   console.log(keywordState);
  // };

  // const testSort = () => {
  //   sortData(rowData, { sortBy, reversed: reverseSortDirection, keywordState });
  //   console.log('gg');
  // };

  const tableColumn = header.map((label) => (
    // console.log(label)

    <Th
      key={label.title} // {title: 'NickName'}
      sorted={sortBy === label.title}
      reversed={reverseSortDirection}
      onSort={() =>
        // testSort()
        setSorting(
          label.title as keyof (
            | DiagnosisRowData
            | ProduceSkinRowData
            | ProduceScalpRowData
          )
        )
      }
    >
      <span style={{ fontWeight: 800 }}>{label.title}</span>
    </Th>
  ));

  // 프린트 아이콘 클릭 이벤트
  const printModalOpen = async (
    regDt: string,
    type: string,
    skinCode: string,
    conceptParams: string,
    languageState: string | null,
    options: string
  ) => {
    console.log('open');

    window.open(
      `/ganzi/${groupInfo}/${regDt}/${languageState}/${type}/${skinCode}${conceptParams}${options}`,
      '_blank',
      'popup, height=1748, width=1240, realzable=yes, location=yes, '
    );
  };

  const rows = sortedData.map((row) => (
    <Table.Tr key={row.name}>
      <Table.Td>{row.name}</Table.Td>
      <Table.Td>{row.email}</Table.Td>
      <Table.Td>{row.company}</Table.Td>
    </Table.Tr>
  ));
  // console.log(header);

  // data를 사용해 테이블의 행을 만듭니다.
  const diagnosisTableRows = (filteringData as DiagnosisRowData[])?.map(
    (item, index) => (
      <Table.Tr key={index}>
        <Table.Td>
          {item.managerGroup}
          <br />
          {item.managerName}
        </Table.Td>
        <Table.Td>{item.prodType}</Table.Td>
        <Table.Td>
          {item.nickname}
          <br />
          {t(`manager.gender.${item.gender === 'MAN' ? '남' : '여'}`)}
          <br />
          {item.age}
        </Table.Td>
        <Table.Td>
          {t(
            `manager.${diagTypeState === 'skin' ? 'base' : 'scalpBase'}.${item.base}`
          )}
        </Table.Td>
        <Table.Td>
          {diagTypeState === 'skin' ? t(`manager.oil.${item.oil}`) : ''}
        </Table.Td>
        <Table.Td>
          {t(
            `manager.${diagTypeState === 'skin' ? 'concept' : 'scalpConcept'}.${item.concept1}`
          )}
        </Table.Td>
        <Table.Td>
          {t(
            `manager.${diagTypeState === 'skin' ? 'concept' : 'scalpConcept'}.${item.concept2}`
          )}
        </Table.Td>
        {item.concept3 && (
          <Table.Td>
            {t(
              `manager.${diagTypeState === 'skin' ? 'concept' : 'scalpConcept'}.${item.concept3}`
            )}
          </Table.Td>
        )}

        <Table.Td>{item.skinCode}</Table.Td>
        <Table.Td>
          {/* <a href={`https://popupdiagapp-res.lillycover.com/${$page.params.lang}/${item.skinCode}`}></a> */}
          {type === 'skin' && (
            <a
              href={`https://popupdiagapp-res.lillycover.com/${languageState}/${item.skinCode}`}
              target="_blank"
            >
              <IconLink />
            </a>
          )}
          {type === 'scalp' && (
            <a
              href={`https://popupdiagapp-res.lillycover.com/${languageState}/scalp/${item.skinCode}`}
              target="_blank"
            >
              <IconLink />
            </a>
          )}
        </Table.Td>
        <Table.Td>
          <a
            onClick={() =>
              printModalOpen(
                item.regDt,
                item.prodType,
                item.skinCode,
                '?concept1=1&concept2=1&concept3=1',
                languageState,
                '&option=30ml'
              )
            }
          >
            <IconPrinter />
          </a>{' '}
          {/* {printOpend && (
            <PrintModal
              ganziComponentRef={ganziComponentRef}
              opened={printOpend}
              close={printHandler.close}
              handlePrint={handlePrint}
            />
          )} */}
        </Table.Td>
      </Table.Tr>
    )
  );

  // data를 사용해 테이블의 행을 만듭니다.
  const produceTableRows = (
    diagTypeState === 'skin'
      ? (filteringData as ProduceSkinRowData[])
      : (filteringData as ProduceScalpRowData[])
  )?.map((item, index) => (
    <Table.Tr key={index}>
      <Table.Td>{item.seq}</Table.Td>
      <Table.Td>{item.prodType}</Table.Td>
      <Table.Td>
        {item.name}
        <br />
        {item.gender}
        <br />
        {item.age}
      </Table.Td>
      <Table.Td>{t(`manager.base.${item.base}`)}</Table.Td>
      <Table.Td>{t(`manager.oil.${item.oil}`)}</Table.Td>
      <Table.Td>
        {diagTypeState === 'skin' && 'skinconcept1' in item
          ? t(`manager.concept.${item.skinconcept1}`)
          : ''}
        {diagTypeState === 'scalp' && 'scalpconcept1' in item
          ? t(`manager.concept.${item.scalpconcept1}`)
          : ''}
      </Table.Td>
      <Table.Td>
        {diagTypeState === 'skin' && 'skinconcept2' in item
          ? t(`manager.concept.${item.skinconcept2}`)
          : ''}
        {diagTypeState === 'scalp' && 'scalpconcept2' in item
          ? t(`manager.concept.${item.scalpconcept2}`)
          : ''}
      </Table.Td>
      <Table.Td>
        {diagTypeState === 'skin' && 'skintrouble1' in item
          ? t(`manager.concept.${item.skintrouble1}`)
          : ''}
        {diagTypeState === 'scalp' && 'scalptrouble1' in item
          ? t(`manager.concept.${item.scalptrouble1}`)
          : ''}
      </Table.Td>
      <Table.Td>
        {diagTypeState === 'skin' && 'skintrouble2' in item
          ? t(`manager.concept.${item.skintrouble2}`)
          : ''}
        {diagTypeState === 'scalp' && 'scalptrouble2' in item
          ? t(`manager.concept.${item.scalptrouble2}`)
          : ''}
      </Table.Td>
      <Table.Td>
        {/* <a
          href={`https://popupdiagapp-res.lillycover.com/ko/${
            diagTypeState === 'skin' && 'skinCode' in item
              ? item.skinCode
              : diagTypeState === 'scalp' && 'scalpCode' in item
                ? item.scalpCode
                : ''
          }`}
          target="_blank"
        >
          <IconLink />
        </a> */}
        {diagTypeState === 'skin' && 'skintrouble2' in item
          ? item.skinCode
          : ''}
        {diagTypeState === 'scalp' && 'scalptrouble2' in item
          ? item.scalpCode
          : ''}
      </Table.Td>
    </Table.Tr>
  ));

  console.log(filteringData);

  return (
    <ScrollArea>
      {/* <TextInput
        placeholder="keywordState by any field"
        mb="md"
        leftSection={
          <IconkeywordState
            style={{ width: rem(16), height: rem(16) }}
            stroke={1.5}
          />
        }
        value={keywordState}
        onChange={handlekeywordStateChange}
      /> */}
      <Table
        horizontalSpacing="md"
        verticalSpacing="md"
        miw={1250}
        layout="fixed"
        // striped
        highlightOnHover
        withTableBorder
        withColumnBorders
        styles={{ thead: { backgroundColor: '#edf2ff' } }}
      >
        <Table.Thead>
          <Table.Tr>{tableColumn}</Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {rowData?.length > 0 ? (
            // tableRows
            recordState === 'diagnosis' ? (
              diagnosisTableRows
            ) : (
              produceTableRows
            )
          ) : (
            <Table.Tr>
              <Table.Td colSpan={header.length}>
                {' '}
                {/* header의 길이만큼 colSpan 설정 */}
                <NotFoundData period={period} record={record} type={type} />
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  );
}
