import React from 'react';
import { Modal, Button, Text, Group } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import i18n from '../../assets/locales/i18n';

interface LoginErrorModalProps {
  error: string;
  opened: boolean;
  close: any;
}

const LoginErrorModal = ({ error, opened, close }: LoginErrorModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      opened={opened}
      onClose={close}
      title="LoginError"
      size="xs"
      centered={true}
    >
      <Text size="md" style={{ textAlign: 'center' }} my="md">
        {error}
        <br />
        {t(`manager.login.first`)}
        {t(`manager.login.second`)}
      </Text>
      <Group justify="center">
        <Button onClick={close}>Confirm</Button>
      </Group>
    </Modal>
  );
};

export default LoginErrorModal;
