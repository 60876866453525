import { Box, Stack } from '@mantine/core';
import { default as DiagTypeTabs } from '../../components/tabs/diag/DiagTypeTabs';
import RecordTypeTabs from '../../components/tabs/record/RecordTypeTabs';
import { Header } from '../../components/header/Header';
import classes from './GetList.module.css';
import { GetListTable, Search } from '../../components';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { getSkinRecipeApi, getSkinScoreApi, getListApi } from '../../utils';
import {
  ProduceScalpRowData,
  ProduceSkinRowData,
} from '../../types/ProduceRowData';
import { DiagnosisRowData } from '../../types/DiagnosisRowData';
import i18n from '../../assets/locales/i18n';
import styles from './GetList.module.css';

const GetList = () => {
  const [cookies] = useCookies(['jwt']); // jwt는 저장된 쿠키의 이름
  const navigate = useNavigate();
  const { group } = useParams();
  const [groupInfo, setGroupInfo] = useState('');
  const changeGroupUppercase = () => {
    console.log(1);

    if (group === 'admin') {
      console.log(2);
      setGroupInfo('ROLE_ADMIN');
    } else {
      console.log(groupInfo);
      setGroupInfo(group?.toUpperCase() ?? '');
    }
  };

  // 빈 배열로 두면 페이지 들어왔을 때 딱 한번만 실행
  useEffect(() => {
    changeGroupUppercase();
    i18n.changeLanguage(languageState!);
  }, []);

  // groupInfo가 바뀔 때마다 초기 언어를 변경하기 위한 useEffect
  useEffect(() => {
    console.log(groupInfo);
  }, [groupInfo]);

  // 로그인 여부 확인 후 미로그인 시 로그인 유도
  useEffect(() => {
    if (!cookies.jwt) {
      // jwt 쿠키가 없으면 로그인 페이지로 리다이렉트
      navigate('/login');
    }
  }, [cookies, navigate]);

  // 다국어 선택 상태값
  const [languageState, setLanguageState] = useState<string | null>(
    group === 'lilyari' ? 'cn' : group === 'smartskn' ? 'en' : 'ko'
  );

  // [진단 기록 / 생산 기록] 상태값
  const [recordState, setRecordState] = useState<string | null>('diagnosis');
  // [skin / scalp] 상태값
  const [diagTypeState, setDiagTypeState] = useState<string>('skin');
  // 검색 - 날짜 상태값
  const [periodState, setPeriodState] = useState<[Date | null, Date | null]>([
    new Date(),
    new Date(),
  ]);
  // 검색 - 키워드 상태값
  const [keywordState, setKeywordState] = useState<string>('');

  // 검색 - 돋보기 엔터 상태값
  const [enterSearchState, setEnterSearchState] = useState<boolean>(false);

  // 검색 - Date >>>>> String
  const formatDateToString = (date: Date | null) => {
    if (date) {
      return format(date, 'yyyy-MM-dd');
    }
    return format(new Date(), 'yyyy-mm-dd');
  };

  // 날짜 포맷 Date >> String
  const [formattedPeriodState, setFormattedPeriodState] = useState<
    [string, string]
  >([formatDateToString(periodState[0]), formatDateToString(periodState[1])]);

  useEffect(() => {
    setFormattedPeriodState([
      formatDateToString(periodState[0]),
      formatDateToString(periodState[1]),
    ]);
  }, [periodState]);

  // recordState에 따른 table Column값
  const diagHeader = [
    { title: 'Manager' },
    { title: 'Type' },
    { title: 'NickName' },
    { title: 'Base' },
    { title: 'Oil' },
    { title: 'Concept1' },
    { title: 'Concept2' },
    ...(diagTypeState === 'scalp'
      ? [{ title: 'Concept3' }]
      : []),
    { title: 'SkinCode' },
    { title: 'Link' },
    { title: 'Print' },
  ];
  const produceHeader = [
    { title: 'No' },
    { title: 'Type' },
    { title: 'NickName' },
    { title: 'Base' },
    { title: 'Oil' },
    { title: 'Concept1' },
    { title: 'Concept2' },
    { title: 'Concept3' },
    { title: 'Concept4' },
    { title: 'SkinCode' },
  ];

  // API로부터 가져온 데이터를 저장하기 위한 상태값 >> table row 값
  const [rowData, setRowData] = useState<
    (DiagnosisRowData | ProduceSkinRowData | ProduceScalpRowData)[]
  >([]);

  console.log(recordState); // 진단기록/생산기록
  console.log(diagTypeState); // Skin/Scalp
  console.log(languageState); // 언어
  console.log(formattedPeriodState); // [startDate, endDate]
  console.log(keywordState); // 검색 키워드
  console.log(group); // 로그인에서 파라미터로 받아오는 group값
  console.log(groupInfo); // 페이로드로 보낼 정돈된 group값
  console.log(cookies.jwt); // 토큰값
  console.log(rowData);

  console.log(new Date().toString());

  //
  const onFetch = async () => {
    const callApi: any = await getListApi(
      recordState,
      diagTypeState,
      {
        group: groupInfo,
        startDt: formattedPeriodState[0],
        endDt: formattedPeriodState[1],
        keyword: keywordState,
      },
      cookies.jwt
    );
    // console.log('변화중~')

    console.log(callApi);

    if (callApi.contents) {
      if (recordState === 'diagnosis') {
        setRowData(
          callApi.contents[
            diagTypeState === 'skin'
              ? 'diagnosisListResultVo'
              : 'scalpListResultVo'
          ]
        );
      } else {
        setRowData(callApi.contents);
      }
    } else {
      setRowData([]);
    }
  };

  // API 호출을 위한 useEffect
  useEffect(() => {
    onFetch();
  }, [recordState, diagTypeState, groupInfo]);

  useEffect(() => {
    onFetch();
    console.log(formattedPeriodState);
  }, [formattedPeriodState]);

  console.log(`ㅇㄷ냐고 ${languageState}`);

  return (
    <div className={`${styles.getListContainer}`}>
      {/* <Container pl={'32px'} pr={'32px'}> */}
      <Header
        languageState={languageState}
        setLanguageState={setLanguageState}
        groupInfo={groupInfo}
      />
      <Stack>
        <Box className={classes.getListTabs}>
          {/* <LangTabs /> */}
          <RecordTypeTabs
            recordState={recordState}
            setRecordState={setRecordState}
          />
          <DiagTypeTabs
            diagTypeState={diagTypeState}
            setDiagTypeState={setDiagTypeState}
          />
        </Box>
        <Search
          periodState={periodState}
          setPeriodState={setPeriodState}
          keywordState={keywordState}
          setKeywordState={setKeywordState}
          onClick={() => onFetch()}
          groupInfo={groupInfo}
          header={recordState === 'diagnosis' ? diagHeader : produceHeader}
          rowData={rowData}
          record={recordState}
          type={diagTypeState}
        />
        <GetListTable
          header={recordState === 'diagnosis' ? diagHeader : produceHeader}
          rowData={
            rowData as (
              | DiagnosisRowData
              | ProduceSkinRowData
              | ProduceScalpRowData
            )[]
          }
          // tableRows={
          //   recordState === 'diagnosis' ? diagnosisTableRows : produceTableRows
          // }
          keywordState={keywordState}
          recordState={recordState}
          diagTypeState={diagTypeState}
          languageState={languageState}
          period={formattedPeriodState}
          record={recordState}
          type={diagTypeState}
          groupInfo={groupInfo}
        />
      </Stack>
      {/* </Container> */}
    </div>
  );
};

export default GetList;
