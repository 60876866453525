import React from 'react';
import ReactDOM from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import './index.css';
import App from './App';
// import App from './test/App';
import './assets/locales/i18n';
import './assets/fonts/font.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <MantineProvider
      theme={
        {
          // 여기에 테마 설정을 넣을 수 있습니다.
          // 예: primary 색상 변경, 폰트 설정 등
        }
      }
    >
      <App />
    </MantineProvider>
  </>
);
