import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { DatePickerInput } from '@mantine/dates';

import {
  TextInput,
  TextInputProps,
  ActionIcon,
  rem,
  Flex,
  UnstyledButton,
  Button,
} from '@mantine/core';
import {
  IconSearch,
  IconArrowRight,
  IconRotateClockwise,
  IconDownload,
} from '@tabler/icons-react';
import {
  ProduceScalpRowData,
  ProduceSkinRowData,
} from '../types/ProduceRowData';
import { DiagnosisRowData } from '../types/DiagnosisRowData';
import { addMonths, format } from 'date-fns';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import i18n from '../assets/locales/i18n';
import PeriodErrorModal from './modal/PeriodErrorModal';
import { error } from 'console';

interface SearchProps {
  periodState: [Date | null, Date | null];
  setPeriodState: (value: [Date | null, Date | null]) => void;
  keywordState: string;
  setKeywordState: (value: string) => void;
  onClick: () => void;
  groupInfo: string;
  header: { title: string }[];
  rowData: (DiagnosisRowData | ProduceSkinRowData | ProduceScalpRowData)[];
  record: string | null;
  type: string;
}

const Search = ({
  periodState,
  setPeriodState,
  keywordState,
  setKeywordState,
  onClick,
  groupInfo,
  header,
  rowData,
  record,
  type,
}: SearchProps) => {
  console.log(periodState);
  const [errorOpend, errorHandler] = useDisclosure(false);

  // keyword 상태값 저장
  const searchKeyword = (event: any) => {
    setKeywordState(event.target.value);
  };

  // console.log(keywordState);

  // 초기화, 리셋 기능
  const resetOnclick = () => {
    setPeriodState([new Date(), new Date()]);
    setKeywordState('');
  };

  // CSV 형식에 맞는 데이터를 생성하는 함수
  const getCsvData = (data: any) => {
    return data.map((item: any) => {
      if (record === 'diagnosis') {
        if (type === 'skin') {
          return {
            Manager: `${item.managerGroup}, ${item.managerName}`,
            Type: item.prodType,
            NickName: `${item.nickname}, ${item.gender}, ${item.age}`,
            Base: item.base,
            Oil: item.oil,
            Concept1: item.concept1,
            Concept2: item.concept2,
            SkinCode: item.skinCode,
            Link: item.qrCodeUrl,
          };
        } else {
          return {
            Manager: `${item.managerGroup}, ${item.managerName}`,
            Type: item.prodType,
            NickName: `${item.nickname}, ${item.gender}, ${item.age}`,
            Base: item.base,
            Oil: item.oil,
            Concept1: item.concept1,
            Concept2: item.concept2,
            Concept3: item.concept3,
            SkinCode: item.skinCode,
            Link: item.qrCodeUrl,
          };
        }
      } else if (record === 'produce') {
        if (type === 'skin') {
          return {
            No: item.seq,
            Type: item.prodType,
            NickName: `${item.name}, ${item.gender}, ${item.age}`,
            Base: item.base,
            Oil: item.oil,
            Concept1: item.skinconcept1,
            Concept2: item.skinconcept2,
            Concept3: item.skintrouble1,
            Concept4: item.skintrouble2,
            SkinCode: item.skinCode,
          };
        } else {
          return {
            No: item.seq,
            Type: item.prodType,
            NickName: `${item.name}, ${item.gender}, ${item.age}`,
            Base: item.base,
            Oil: item.oil,
            Concept1: item.skinconcept1,
            Concept2: item.skinconcept2,
            Concept3: item.skintrouble1,
            Concept4: item.skintrouble2,
            SkinCode: item.skinCode,
          };
        }
      }
    });
  };

  console.log(periodState);
  // 파일명 생성 함수
  const createFileName = () => {
    // 예: 'groupInfo_recordState_diagType_periodState.csv'
    const startDate = periodState[0]
      ? format(periodState[0], 'yyyyMMdd')
      : 'start';
    const endDate = periodState[1] ? format(periodState[1], 'yyyyMMdd') : 'end';
    console.log(startDate, endDate);
    return `${groupInfo}_${record}_${type}_${startDate}_to_${endDate}.csv`;
  };

  // 뭐지? useRef가 document.querySelect랑 똑같음
  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  // 엑셀 파일 다운로드 버튼 클릭 시 CSVLink의 링크를 클릭하도록 설정
  const handleDownloadClick = () => {
    if (csvLink.current) {
      csvLink.current.link.click();
    }
  };

  // 조회 기간 마지노선을 1달로 제한
  const handlePeriodChange = (dates: [Date | null, Date | null]) => {
    const [startDate, endDate] = dates;

    if (startDate && endDate) {
      // 시작 날짜로부터 1달 후의 날짜를 계산
      const maxEndDate = addMonths(startDate, 12);

      // 선택한 종료 날짜가 계산된 최대 종료 날짜를 초과하는 경우
      if (endDate > maxEndDate) {
        // alert('날짜 조회 기간은 최대 1달로 제한됩니다.');

        errorHandler.open();
        // 종료 날짜를 최대 종료 날짜로 설정
        setPeriodState([startDate, maxEndDate]);
      } else {
        // 선택한 기간이 1달 이내인 경우 상태 업데이트
        setPeriodState(dates);
      }
    } else {
      // 날짜 중 하나라도 null인 경우, 즉 사용자가 날짜를 모두 지웠을 때 상태 업데이트
      setPeriodState(dates);
    }
  };

  const { t } = useTranslation();

  return (
    <Flex justify="space-between">
      <Flex gap="md">
        <DatePickerInput
          type="range"
          valueFormat="YYYY. MM. DD"
          value={periodState}
          placeholder="Pick dates range"
          onChange={handlePeriodChange}
          styles={{
            input: { height: '42px' },
          }}
        />
        <TextInput
          radius="sm"
          size="md"
          placeholder={t(`manager.filter.placeholder`)}
          value={keywordState || ''}
          onChange={searchKeyword}
          leftSectionWidth={42}
          leftSection={
            <IconSearch
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          }
          // {...props} // value랑 onChange >> textInput에 사용할 속성 추가로 받아올 수 있게 해놓은 것
        />
        <Button
          onClick={() => {
            resetOnclick();
          }}
          h="42px"
          variant="default"
        >
          <IconRotateClockwise style={{ width: rem(18), height: rem(18) }} />
        </Button>
      </Flex>
      {/* 초기화 버튼 클릭 시 모든 상태값을 setState에서 초기화 해주기 */}
      <CSVLink
        headers={header.map((col) => ({
          label: col.title,
          key: col.title,
        }))}
        data={getCsvData(rowData)}
        filename={createFileName()}
        target="_blank"
        ref={csvLink}
        onClick={() => {
          console.log('엑셀 다운로드 버튼 클릭함');
          console.log(rowData);
        }}
      />
      <Button
        h="42px"
        variant="default"
        rightSection={<IconDownload size={14} />}
        onClick={handleDownloadClick}
      >
        Excel
      </Button>
      {errorOpend && (
        <PeriodErrorModal opened={errorOpend} close={errorHandler.close} />
      )}
    </Flex>
  );
};

export default Search;
