import { get } from './api';

// 피부 간지 정보
export const getSkinRecipeApi = async (skinCode: string) => {
  const path = `godo/getResult/${skinCode}`;
  try {
    const response = await get(path);
    return response;
  } catch (error) {
    console.log('API 호출 중 에러가 발생했습니다:', error);
  }
};

export const getSkinScoreApi = async (skinCode: string) => {
  const path = `/diagnosis/result/new/${skinCode}`;
  try {
    const response = await get(path);
    return response;
  } catch (error) {
    console.log('API 호출 중 에러가 발생했습니다:', error);
  }
};

// 두피 간지 정보
export const getScalpRecipeApi = async (skinCode: string) => {
  const path = `godo/getScalpResult/${skinCode}`;
  try {
    const response = await get(path);
    return response;
  } catch (error) {
    console.log('API 호출 중 에러가 발생했습니다:', error);
  }
};

export const getScalpScoreApi = async (skinCode: string) => {
  const path = `/diagnosis/scalpResult/new/${skinCode}`;
  try {
    const response = await get(path);
    return response;
  } catch (error) {
    console.log('API 호출 중 에러가 발생했습니다:', error);
  }
};
