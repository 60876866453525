import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextInput, Button, Box, Center, PasswordInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useLogin } from '../../utils/useLogin'; // utils 폴더 내의 useLogin 훅을 가져옴

import { useTranslation } from 'react-i18next';
import i18n from '../../assets/locales/i18n';
import LoginErrorModal from '../../components/modal/LoginErrorModal';

const Login = () => {
  const navigate = useNavigate();
  const { login, loading, error } = useLogin();
  const [id, setId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorOpend, errorHandler] = useDisclosure(false);
  const { t } = useTranslation();

  // console.log(error);

  // useEffect를 사용하여 error 상태를 감시하고 에러 모달을 띄우기
  useEffect(() => {
    // error 상태가 true 값이면 모달 상태를 true로 설정
    if (error) {
      console.log('에러 발생');
      console.log(errorOpend);
    }
  }, [error]); // error 상태가 변경될 때마다 이 효과를 실행

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // await login(id, password);
    try {
      // api 호출 후 결과 기다리기
      const response = await login(id, password);
      console.log(response?.response);
      console.log(error);
      // 로그인 성공 시 소속 그룹을 확인하고 해당 getListSkin 조회 페이지로 이동 (Lilyari, SmartSkn, Balanx)
      // * response.token === 'LILYARI', response.token === 'SMARTSKN', response.token === 'BALANX || ROLE_ADMIN' 이걸로 판별할 것 *
      if (response?.response.status === 200) {
        switch (response?.response.token) {
          case 'LILYARI':
            console.log('user: LILYARI');
            navigate('/getlist/lilyari');
            break;
          case 'SMARTSKN':
            console.log('user: SMARTSKN');
            navigate('/getlist/smartskn');
            break;
          case 'LILLYCOVER':
            console.log('user: LILLYCOVER');
            navigate('/getlist/lillycover');
            break;
          case 'BALANX':
            console.log('user: BALANX');
            // navigate('/getListSkin');
            navigate('/getlist/balanx');
            break;
          case 'ROLE_ADMIN':
            console.log('user: ROLE_ADMIN');
            // navigate('/getListSkin');
            navigate('/getlist/admin');
            break;
          default:
            // 서버로부터 올바른 토큰을 받지 못한 경우
            // 에러 모달창 표시 로직을 구현
            errorHandler.open();
            break;
        }
      } else if (response?.response.status === 435) {
        // 로그인 실패 시 에러 상태 설정
        errorHandler.open();

        console.log('아이디나 비밀번호 다시 확인하시길...');
      } else {
        // 로그인 실패 시 에러 상태 설정
        errorHandler.open();

        console.log('Invalid username or password');
      }
    } catch (err) {
      // 네트워크 에러나 기타 예외 처리
      errorHandler.open();

      console.log('Login failed. Please try again later.');
    }
  };

  return (
    <Center style={{ height: '100vh' }}>
      <Box style={{ width: 300 }} mx="auto">
        <form onSubmit={handleSubmit}>
          <TextInput
            label="ID"
            placeholder="Enter your ID"
            value={id}
            onChange={(event) => setId(event.currentTarget.value)}
            required
          />
          <PasswordInput
            label="Password"
            placeholder="Enter your password"
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            required
            mt="md"
          />
          <Button type="submit" fullWidth mt="lg" loading={loading}>
            {t(`login`)}
          </Button>
        </form>
        {errorOpend && (
          // <Notification title="Error" color="red" mt="lg">
          //   {error}
          // </Notification>
          <LoginErrorModal
            error={error}
            opened={errorOpend}
            close={errorHandler.close}
          />
        )}
      </Box>
    </Center>
  );
};

export default Login;
