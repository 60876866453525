// utils/useLogin.ts
import { useState } from 'react';
import { post } from './api'; // post 함수를 api.ts 파일에서 가져옴
import { useCookies } from 'react-cookie';

interface LoginRequest {
  account: string;
  password: string;
}

interface LoginResponse {
  // token: string;
  // status: number;
  response: {
    message: string;
    status: number;
    errorCode: string;
    token: string;
  };
  contents: Array<{
    token: string;
  }>;
}

// 로그인 API 호출 함수
async function login(
  account: string,
  password: string
): Promise<LoginResponse | null> {
  const path = 'login'; // API 엔드포인트 경로
  const data: LoginRequest = { account, password };

  try {
    const response = await post<LoginResponse>(
      path,
      data,
      undefined,
      undefined
    );
    // API 호출 성공 시, 응답 객체 전체를 반환
    return response;
  } catch (err) {
    // API 호출 실패 시, null 반환
    return null;
  }
}

// useLogin 훅
export function useLogin() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [errorCode, setErrorCode] = useState<string>('');
  const [cookies, setCookie] = useCookies(['jwt']);

  const handleLogin = async (
    account: string,
    password: string
  ): Promise<LoginResponse | null> => {
    setLoading(true);
    setError('');
    setErrorCode('');
    try {
      const response = await login(account, password);
      if (response && response.response.status === 200) {
        // 토큰 처리 로직
        console.log('Login successful:', response.contents[0].token);
        setCookie('jwt', response.contents[0].token);
        return response;
      } else {
        // 로그인 실패
        setError(response!.response.message);
        setErrorCode('AUTH06');
        return null;
      }
    } catch (err) {
      setError('Login failed. Please check your credentials and try again.');
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { login: handleLogin, loading, error };
}
