import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en/translation.json';
import translationKO from './ko/translation.json';
import translationCN from './cn/translation.json';
import translationJP from './jp/translation.json';
import translationES from './es/translation.json';
import translationFR from './fr/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  ko: {
    translation: translationKO,
  },
  cn: {
    translation: translationCN,
  },
  jp: {
    translation: translationJP,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(initReactI18next) // react-i18next init
  .init({
    resources,
    lng: 'en', // 기본 설정 언어, 'cimode'로 설정할 경우 키 값으로 출력됨
    fallbackLng: 'en', // 번역 파일에서 찾을 수 없는 경우 기본 언어
    debug: true,
    interpolation: {
      escapeValue: false, // XSS 공격 방지를 위해 값 이스케이프 설정
    },
  });

export default i18n;
