// FrontSection.tsx
import React, { useEffect, useState } from 'react';
import styles from './FrontSection.module.css';
import { useTranslation } from 'react-i18next';
import { SkinRecipeProps } from '../../../../types/SkinRecipeProps';
import { SkinScoreProps } from '../../../../types/SkinScoreProps';
import ganziImgae from '../../../../assets/images/ganzi_skin_img.png';
import { Center, Flex, Space, Image } from '@mantine/core';
import balanxLogo from '../../../../assets/images/BALANX_logo.svg';
import lilyariLogo from '../../../../assets/images/LILYARI_logo.png';
import smartSknLogo from '../../../../assets/images/SMARTSKN_logo.png';

interface FrontSectionProps {
  recipe: SkinRecipeProps;
  score: SkinScoreProps;
  prodType: string;
  concept1: string;
  concept2: string;
  concept3: string;
  options: string | null;
  selectedRecipe: { [key: string]: string }[] | undefined;
  groupInfo: string;
}

export function SkinFrontSection({
  recipe,
  score,
  prodType,
  concept1,
  concept2,
  options,
  selectedRecipe,
  groupInfo,
}: FrontSectionProps) {
  const { t } = useTranslation();

  console.log(recipe);
  console.log(score);

  return (
    <div className={styles.container}>
      <div className={styles.padding}>
        {/* *** 님의 맞춤형 ** (??)ml */}
        <div className={styles.top}>
          <Flex direction={'column'} gap={1}>
            <div>
              <span
                className={`${styles.name1} ${styles.fwxl} ${styles.fs10xl}`}
              >
                {recipe?.name}
              </span>{' '}
              <span
                className={`${styles.name2} ${styles.fwmd} ${styles.fs5xl}`}
              >
                {t(`ganzi.skin.front.header.님의`)}
              </span>
            </div>
            <div>
              <span className={`${styles.fwmd} ${styles.fs2xl}`}>
                {t(`ganzi.skin.front.header.맞춤형`)}
              </span>{' '}
              <span className={`${styles.fwmd} ${styles.fs2xl}`}>
                {t(`ganzi.skin.front.header.${prodType}`)}
              </span>{' '}
              <span className={`${styles.fwmd} ${styles.fs2xl}`}>
                {options}
              </span>
            </div>
          </Flex>
        </div>
        {/* 이미지 */}
        <div className={styles.middle}>
          <img className={styles.img} src={ganziImgae} alt="" />
        </div>
        {/* 맞춤형 조제 레시피 */}
        <div className={styles.bottom}>
          <div className={styles.bottom_header}>
            <div>
              <span
                className={`${styles.fwlg} ${styles.fs2xl} ${styles.bottom_title}`}
              >
                {t(`ganzi.skin.front.recipe.title1`)}{' '}
              </span>
              <span className={`${styles.fwmd} ${styles.fslg}`}>
                {' '}
                {t(`ganzi.skin.front.recipe.title2`)}
              </span>
            </div>
            <span className={`${styles.fwmd} ${styles.fsmd}`}>
              {t(`ganzi.skin.front.recipe.title3`)}
            </span>
          </div>
          <div className={styles.bottom_content}>
            {/* <div>
              <p>{t(`ganzi.skin.front.recipe.base.title`)}</p>
            </div> */}
            <div className={styles.customRecipeTable}>
              <div className={`${styles.tableRow}`}>
                <div className={styles.tableTr}>
                  <span
                    className={`${styles.tdLeft} ${styles.fwlg} ${styles.fssm}`}
                  >
                    {t('ganzi.skin.front.recipe.base.title')}
                  </span>
                  <span className={`${styles.tdRight} ${styles.fsmd}`}></span>
                </div>
                <div className={styles.tableTr}>
                  <span
                    className={`${styles.tdLeft} ${styles.fwmd} ${styles.fsmd}`}
                  >
                    {t(
                      `ganzi.skin.front.recipe.base.code.${selectedRecipe && selectedRecipe[0].baseCode}`
                    )}
                  </span>
                  <span
                    className={`${styles.tdRight} ${styles.fsmd} ${styles.fwmd}`}
                  >
                    {t(
                      `ganzi.skin.front.recipe.base.baseTextShort.${selectedRecipe && selectedRecipe[0].baseCode}`
                    )}
                  </span>
                </div>
              </div>
              <div className={`${styles.tableRow}`}>
                <div className={styles.tableTr}>
                  <span
                    className={`${styles.tdLeft}  ${styles.fwlg} ${styles.fssm}`}
                  >
                    {t('ganzi.skin.front.recipe.oil.title')}
                  </span>
                  <span className={`${styles.tdRight} ${styles.fsmd}`}></span>
                </div>
                <div className={styles.tableTr}>
                  <span
                    className={`${styles.tdLeft}  ${styles.fwmd} ${styles.fsmd}`}
                  >
                    {t(
                      `ganzi.skin.front.recipe.oil.code.${selectedRecipe && selectedRecipe[0].oilCode}`
                    )}
                  </span>
                  <span
                    className={`${styles.tdRight} ${styles.fsmd} ${styles.fwmd}`}
                  >
                    {t(
                      `ganzi.skin.front.recipe.oil.oilTextShort.${selectedRecipe && selectedRecipe[0].oilCode}`
                    )}
                  </span>
                </div>
              </div>
              <div className={`${styles.tableRow}`}>
                <div className={styles.tableTr}>
                  <span
                    className={`${styles.tdLeft}  ${styles.fwlg} ${styles.fssm}`}
                  >
                    {t('ganzi.skin.front.recipe.concept.title')}
                  </span>
                  <span className={`${styles.tdRight} ${styles.fsmd}`}></span>
                </div>
                {concept1 === '1' && (
                  <>
                    <div className={styles.tableTr}>
                      <span
                        className={`${styles.tdLeft} ${styles.fwlg} ${styles.fssm}`}
                      >
                        {t(
                          `ganzi.skin.front.recipe.concept.code.${selectedRecipe && selectedRecipe[0].function1Code}`
                        )}
                      </span>
                      <span
                        className={`${styles.tdRight} ${styles.fsmd}`}
                      ></span>
                    </div>
                    <div className={styles.tableTr}>
                      <span
                        className={`${styles.tdLeft}  ${styles.fwmd} ${styles.fsmd}`}
                      >
                        {t(
                          `ganzi.skin.front.recipe.concept.conceptTextShort.${selectedRecipe && selectedRecipe[0].function1Code}`
                        )}
                      </span>
                      <span
                        className={`${styles.tdRight} ${styles.fsmd} ${styles.fwmd}`}
                      >
                        {t(
                          `ganzi.skin.front.recipe.concept.conceptTextLong.${selectedRecipe && selectedRecipe[0].function1Code}`
                        )}
                      </span>
                    </div>
                  </>
                )}
              </div>

              <div className={`${styles.tableRow} ${styles.noneBorder}`}>
                {concept2 === '1' && (
                  <>
                    <div className={styles.tableTr}>
                      <span
                        className={`${styles.tdLeft}  ${styles.fwlg} ${styles.fssm}`}
                      >
                        {t(
                          `ganzi.skin.front.recipe.concept.code.${selectedRecipe && selectedRecipe[0].function2Code}`
                        )}
                      </span>
                      <span
                        className={`${styles.tdRight} ${styles.fsmd}`}
                      ></span>
                    </div>
                    <div className={styles.tableTr}>
                      <span
                        className={`${styles.tdLeft}  ${styles.fwmd} ${styles.fsmd}`}
                      >
                        {t(
                          `ganzi.skin.front.recipe.concept.conceptTextShort.${selectedRecipe && selectedRecipe[0].function2Code}`
                        )}
                      </span>
                      <span
                        className={`${styles.tdRight} ${styles.fsmd} ${styles.fwmd}`}
                      >
                        {t(
                          `ganzi.skin.front.recipe.concept.conceptTextLong.${selectedRecipe && selectedRecipe[0].function2Code}`
                        )}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* {selectedRecipe && (
          <>
            <p>{selectedRecipe && selectedRecipe[0]?.base}</p>
            <p>{selectedRecipe && selectedRecipe[0]?.baseCode}</p>
            <p>{selectedRecipe && selectedRecipe[0]?.base}</p>
          </>
        )} */}
        {/* <p>{score?.oilMoisture}</p> */}
        {/* <p>{t(``)}</p> */}
        <Space pb={350} />
        <Center>
          <Image
            height={50}
            src={
              groupInfo === 'LILYARI'
                ? lilyariLogo
                : groupInfo === 'SMARTSKN'
                  ? smartSknLogo
                  : balanxLogo
            }
            style={{ color: 'black' }}
          />
        </Center>
      </div>
    </div>
  );
}
