import { Flex, Button, MultiSelect } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../assets/locales/i18n';
import { useReactToPrint } from 'react-to-print';
import {
  getSkinRecipeApi,
  getSkinScoreApi,
  getScalpRecipeApi,
  getScalpScoreApi,
} from '../../utils/getGanziApi';
import { SkinRecipeProps } from '../../types/SkinRecipeProps';
import { SkinScoreProps } from '../../types/SkinScoreProps';
import { ScalpRecipeProps } from '../../types/ScalpRecipeProps';
import { ScalpScoreProps } from '../../types/ScalpScoreProps';
import {
  ScalpBackSection,
  ScalpFrontSection,
  SkinBackSection,
  SkinFrontSection,
} from '../../components/ganzi';
import styles from './Ganzi.module.css';

const Ganzi = () => {
  const { t } = useTranslation();
  const { skincode, type, lang, groupInfo, regDt } = useParams<string>();
  const [selectedConcepts, setSelectedConcepts] = useState<string[]>([]);
  const [selectedMl, setSelectedMl] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [skinRecipe, setSkinRecipe] = useState<SkinRecipeProps>();
  const [skinScore, setSkinScore] = useState<SkinScoreProps>();
  const [scalpRecipe, setScalpRecipe] = useState<ScalpRecipeProps>();
  const [scalpScore, setScalpScore] = useState<ScalpScoreProps>();

  const [concept1Value, setConcept1Value] = useState<string>('');
  const [concept2Value, setConcept2Value] = useState<string>('');
  const [concept3Value, setConcept3Value] = useState<string>('');
  const [conceptValue, setConceptValue] = useState<{ [key: string]: string }>(
    {}
  );
  const [optionsValue, setOptionsValue] = useState<string | null>('');

  console.log(searchParams);

  useEffect(() => {
    // URL 파라미터에서 concept 값을 읽어와서 선택된 항목으로 설정
    // 컨셉성분
    const concepts = ['concept1', 'concept2', 'concept3'];
    const selected = concepts
      .filter((concept) => searchParams.get(concept) === '1')
      .map((concept) => concept); // 예: ['concept1', 'concept3']
    setSelectedConcepts(selected);

    // ml
    const optionParam = searchParams.get('option');
    if (optionParam) {
      setSelectedMl(optionParam.split(','));
    }

    // 값 따내기
    setConcept1Value(searchParams.get('concept1') === '1' ? '1' : '0');
    setConcept2Value(searchParams.get('concept2') === '1' ? '1' : '0');
    setConcept3Value(searchParams.get('concept3') === '1' ? '1' : '0');

    setOptionsValue(searchParams.get('option')); // '15ml', '30ml', '100ml', '250ml' 등

    console.log(concept1Value, concept2Value, concept3Value, optionsValue);
  }, [searchParams]);

  // 멀티셀렉터로 컨셉성분 파라미터 값 변경
  const handleConceptChange = (selectedValue: string[]) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    // 모든 concept에 대해 파라미터를 업데이트 (선택된 경우 '1', 그렇지 않은 경우 '0')
    ['concept1', 'concept2', 'concept3'].forEach((concept, index) => {
      newSearchParams.set(
        concept,
        selectedValue.includes(`concept${index + 1}`) ? '1' : '0'
      );
    });

    setSearchParams(newSearchParams);
  };

  console.log(selectedConcepts);
  console.log(searchParams);

  // 멀티셀렉터로 용량(ml) 파라미터 값 변경
  const handleMlChange = (selectedValue: string[]) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    // 선택된 마지막 값만을 유지, 이는 사용자가 여러 값을 선택할 수 없도록 함
    const lastSelectedValue = selectedValue[selectedValue.length - 1] || '';

    // 'option' 파라미터를 업데이트하거나 추가, 여기서는 선택된 마지막 값만 사용
    newSearchParams.set('option', lastSelectedValue);

    // 선택된 옵션을 업데이트
    setSelectedMl([lastSelectedValue]); // 배열 형태로 업데이트하여 MultiSelect의 value 프로퍼티와 일치

    setSearchParams(newSearchParams);
  };

  // 간지 프린트 기능 (프린터 오픈)
  const ganziComponentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => ganziComponentRef.current,
    documentTitle: '파일 다운로드 시 저장되는 이름',
    onAfterPrint: () => alert('파일 다운로드 후 알림창 생성 가능'),
  });

  // 간지 데이터 API 호출
  const getGanziApi = async (type: string) => {
    if (type === 'LOTION' || type === 'ESSENCE' || type === 'TONER') {
      console.log('1234');
      // 간지 컴포넌트 내부에 표시할 데이터를 API로부터 받아오기
      const getSkinRecipe = await getSkinRecipeApi(skincode!);
      const getSkinScore = await getSkinScoreApi(skincode!);
      setSkinRecipe((getSkinRecipe as any).contents);
      setSkinScore((getSkinScore as any).contents.myScore);
      console.log(skinRecipe, skinScore);
      console.log(type);
    } else {
      const getScalpRecipe = await getScalpRecipeApi(skincode!);
      const getScalpScore = await getScalpScoreApi(skincode!);
      setScalpRecipe((getScalpRecipe as any).contents);
      setScalpScore((getScalpScore as any).contents.scalpMyScore);
      console.log(scalpRecipe, scalpScore);
      console.log(type);
    }
  };

  const fetchData = async () => {
    await i18n.changeLanguage(lang);
    await getGanziApi(type as string);
  };
  useEffect(() => {
    console.log('1111');

    fetchData();
    // 오픈할 때 풀기
    // handlePrint();
  }, []);

  useEffect(() => {
    console.log(skinRecipe, skinScore);
    console.log(scalpRecipe, scalpScore);
    console.log(type);
  }, [skinRecipe, skinScore, scalpRecipe, scalpScore]);

  const concept1 = searchParams.get('concept1');
  const concept2 = searchParams.get('concept2');
  const concept3 = searchParams.get('concept3');

  console.log(skincode, concept1, concept2, concept3);
  console.log(scalpRecipe, scalpScore);

  return (
    <>
      {/* <Flex gap={50}> */}
      <div className={styles.ganziHeader}>
        <MultiSelect
          // variant="unstyled"
          // label="Input label"
          // description="Input description"
          // placeholder="MultiSelect placeholder"
          // data={['React', 'Angular', 'Vue', 'Svelte']}
          data={['concept1', 'concept2', 'concept3']}
          value={selectedConcepts}
          onChange={handleConceptChange}
          label="Select concepts"
          placeholder="Choose concepts..."
        />
        <MultiSelect
          data={['15ml', '30ml', '100ml', '250ml']}
          value={selectedMl}
          onChange={handleMlChange}
          label="Select ml options"
          placeholder="Choose ml Options..."
          // maxValues={1}
        />
        <Button onClick={() => handlePrint()}>Print</Button>
      </div>
      {/* </Flex> */}

      <div ref={ganziComponentRef}>
        {/* {t(`manager.login.first`)}
        <h1>hi</h1> */}
        {(type === 'LOTION' || type === 'ESSENCE' || type === 'TONER') && (
          <div style={{ height: '1240px', width: '1748px' }}>
            <SkinFrontSection
              recipe={skinRecipe!}
              score={skinScore!}
              prodType={type}
              concept1={concept1Value}
              concept2={concept2Value}
              concept3={concept3Value}
              options={optionsValue}
              selectedRecipe={
                skinRecipe && type === 'LOTION'
                  ? skinRecipe.lotionRecipe
                  : type === 'TONER'
                    ? skinRecipe?.tonerRecipe
                    : skinRecipe?.essenceRecipe
              }
              groupInfo={groupInfo!}
            />
            <SkinBackSection
              recipe={skinRecipe!}
              score={skinScore!}
              prodType={type}
              concept1={concept1Value}
              concept2={concept2Value}
              concept3={concept3Value}
              options={optionsValue}
              selectedRecipe={
                skinRecipe && type === 'LOTION'
                  ? skinRecipe.lotionRecipe
                  : type === 'TONER'
                    ? skinRecipe?.tonerRecipe
                    : skinRecipe?.essenceRecipe
              }
              skincode={skincode!}
              regDt={regDt!}
              groupInfo={groupInfo!}
            />
          </div>
        )}
        {(type === 'Shampoo' || type === 'Treatment') && (
          <div style={{ height: '1240px', width: '1748px' }}>
            <ScalpFrontSection
              recipe={scalpRecipe!}
              score={scalpScore!}
              prodType={type}
              concept1={concept1Value}
              concept2={concept2Value}
              concept3={concept3Value}
              options={optionsValue}
              selectedRecipe={
                scalpRecipe && type === 'Shampoo'
                  ? scalpRecipe.shampooRecipe
                  : scalpRecipe?.treatmentRecipe
              }
              groupInfo={groupInfo!}
            />
            <ScalpBackSection
              recipe={scalpRecipe!}
              score={scalpScore!}
              prodType={type}
              concept1={concept1Value}
              concept2={concept2Value}
              concept3={concept3Value}
              options={optionsValue}
              selectedRecipe={
                scalpRecipe && type === 'Shampoo'
                  ? scalpRecipe.shampooRecipe
                  : scalpRecipe?.treatmentRecipe
              }
              skincode={skincode!}
              regDt={regDt!}
              groupInfo={groupInfo!}
            />
          </div>
        )}
        {/* 예를 들어, concept1이 '1'일 때만 해당 컴포넌트를 렌더링 */}
        {/* {concept1 === '1' && <YourComponentForConcept1 />}
        {concept2 === '1' && <YourComponentForConcept2 />}
        {concept3 === '1' && <YourComponentForConcept3 />} 
        {concept4 === '1' && <YourComponentForConcept4 />} */}
      </div>
    </>
  );
};

export default Ganzi;
