import { Title, Text, Button, Container, Group } from '@mantine/core';
import classes from './NotFoundData.module.css';
import { useTranslation } from 'react-i18next';
import i18n from '../../assets/locales/i18n';

interface NotFoundDataProps {
  period: string[];
  record: string | null;
  type: string;
}

export function NotFoundData({ period, record, type }: NotFoundDataProps) {
  const { t } = useTranslation();
  return (
    <Container className={classes.root}>
      <div className={classes.label}>{t(`noData.label`)}</div>
      <Title className={classes.title}>{t(`noData.title`)}</Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        {t(`noData.description1`)}{' '}
        {
          <strong>
            {t(`diagType.${type}`)} {t(`recordType.${record}`)}
          </strong>
        }{' '}
        {t(`noData.description2`)}
        <strong>{period[0]}</strong> to <strong>{period[1]}</strong>
        {t(`noData.description3`)}
      </Text>
      {/* <Group justify="center">
        <Button variant="subtle" size="md">
          Take me back to home page
        </Button>
      </Group> */}
    </Container>
  );
}
