// BackSection.tsx
import React, { useEffect, useState } from 'react';
import styles from './BackSection.module.css';
import { useTranslation } from 'react-i18next';
import { SkinRecipeProps } from '../../../../types/SkinRecipeProps';
import { SkinScoreProps } from '../../../../types/SkinScoreProps';
import balanxLogo from '../../../../assets/images/BALANX_logo.svg';
import lilyariLogo from '../../../../assets/images/LILYARI_logo.png';
import smartSknLogo from '../../../../assets/images/SMARTSKN_logo.png';
import lillyariCircle from '../../../../assets/images/lilyari_circle.png';
import balanxCircle from '../../../../assets/images/balanx_circle.png';
import lillyshopQr from '../../../../assets/images/lillyshop_qr.png';
import A from '../../../../assets/images/mbti/skin/skin_A.png';
import B from '../../../../assets/images/mbti/skin/skin_B.png';
import C from '../../../../assets/images/mbti/skin/skin_C.png';
import D from '../../../../assets/images/mbti/skin/skin_D.png';
import F from '../../../../assets/images/mbti/skin/skin_F.png';
import I from '../../../../assets/images/mbti/skin/skin_I.png';
import O from '../../../../assets/images/mbti/skin/skin_O.png';
import P from '../../../../assets/images/mbti/skin/skin_P.png';
import S from '../../../../assets/images/mbti/skin/skin_S.png';
import T from '../../../../assets/images/mbti/skin/skin_T.png';
import V from '../../../../assets/images/mbti/skin/skin_V.png';

interface BackSectionProps {
  recipe: SkinRecipeProps;
  score: SkinScoreProps;
  prodType: string;
  concept1: string;
  concept2: string;
  concept3: string;
  options: string | null;
  selectedRecipe: { [key: string]: string }[] | undefined;
  skincode: string;
  regDt: string;
  groupInfo: string;
}

// skinType 문자에 해당하는 이미지를 반환하는 함수
const getImageForSkinType = (char: string) => {
  switch (char) {
    case 'A':
      return A;
    case 'B':
      return B;
    case 'C':
      return C;
    case 'D':
      return D;
    case 'F':
      return F;
    case 'I':
      return I;
    case 'O':
      return O;
    case 'P':
      return P;
    case 'S':
      return S;
    case 'T':
      return T;
    case 'V':
      return V;
    default:
      return null;
  }
};

export function SkinBackSection({
  recipe,
  score,
  prodType,
  concept1,
  concept2,
  selectedRecipe,
  skincode,
  regDt,
  groupInfo,
}: BackSectionProps) {
  const { t } = useTranslation();

  console.log(recipe);
  console.log(selectedRecipe && selectedRecipe[0]);
  console.log(score);
  console.log(regDt);
  console.log(selectedRecipe && selectedRecipe[0].baseCode);

  // console.log(options);

  // 제조번호 생성 함수
  const createManufacturingNumber = (regDt: string) => {
    // 날짜 부분 추출
    const datePart = regDt.split(' ')[0].replace(/-/g, ''); // '-' 제거

    // 고유한 번호 생성 (여기서는 마지막 3자리를 위해 타임스탬프를 사용)
    const uniqueNumber = String(Date.now()).slice(-3);

    // 최종 제조번호 생성
    const manufacturingNumber = `LC${datePart}${uniqueNumber}`;

    return manufacturingNumber;
  };

  // skinType 문자열을 배열로 변환
  console.log(recipe?.age);

  console.log(recipe?.skinType.split(''));
  const skinTypeChars = recipe?.skinType.split('');
  // const skinTypeChars = ['O', 'B', 'T', 'V'];

  // 상단 MBTI 이미지
  const mbtiTopImages =
    skinTypeChars &&
    skinTypeChars
      .slice(0, 2)
      .map((char) => (
        <img
          key={`mbti-top-${char}`}
          src={getImageForSkinType(char)!}
          alt={char}
        />
      ));

  // 하단 MBTI 이미지
  const mbtiBottomImages =
    skinTypeChars &&
    skinTypeChars
      .slice(2)
      .map((char) => (
        <img
          key={`mbti-bottom-${char}`}
          src={getImageForSkinType(char)!}
          alt={char}
        />
      ));

  const produceDate = regDt.split(' ')[0];

  // 제조일자를 기반으로 사용기한 계산 함수
  const calculateExpiryDate = (produceDate: string) => {
    // 제조일자 문자열을 Date 객체로 변환
    const dateParts = produceDate.split('-');
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // 월은 0부터 시작
    const day = parseInt(dateParts[2], 10);
    const date = new Date(year, month, day);

    // 제조일로부터 6개월 후의 날짜 계산
    date.setMonth(date.getMonth() + 6);

    // 계산된 사용기한 날짜를 'YYYY.MM.DD' 형식의 문자열로 변환
    const expiryYear = date.getFullYear();
    const expiryMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const expiryDay = date.getDate().toString().padStart(2, '0');

    // 제조일자와 사용기한 날짜를 문자열로 반환
    return `${produceDate.replace(/-/g, '.')} ~ ${expiryYear}.${expiryMonth}.${expiryDay}`;
  };

  // 푸터 구분 (릴리아리 / 국내)
  const renderFooterContent = () => {
    if (groupInfo === 'LILYARI' || groupInfo === 'SMARTSKN') {
      return (
        <div className={`${styles.footer_content_2}`}>
          <div className={`${styles.footer_left_2}`}>
            <img
              className={`${styles.img} ${styles.lillyshopQr}`}
              src={lillyshopQr}
              alt=""
            />
            <div>
              <div className={`${styles.ftTextTr}`}>
                <span className={`${styles.ftText}`}>
                  {t(`ganzi.skin.back.bottom.cn.사용기간`)}{' '}
                </span>
                <span className={`${styles.ftText}`}>
                  {calculateExpiryDate(produceDate)}
                </span>
              </div>
              <div className={`${styles.ftTextTr}`}>
                <span className={`${styles.ftText}`}>
                  {t(`ganzi.skin.back.bottom.cn.판매자`)}
                </span>
                <span className={`${styles.ftText}`}>{groupInfo}</span>
              </div>
              <div className={`${styles.ftTextTr}`}>
                <span className={`${styles.ftText}`}>
                  {t(`ganzi.skin.back.bottom.cn.제조업자`)}
                </span>
                <span className={`${styles.ftText}`}>
                  {prodType === 'TONER' ||
                  prodType === 'LOTION' ||
                  prodType === 'ESSENCE'
                    ? prodType === 'LOTION' &&
                      selectedRecipe &&
                      (selectedRecipe[0].baseCode === 'B0005' ||
                        selectedRecipe[0].baseCode === 'B0013')
                      ? t(`ganzi.skin.back.bottom.cn.로션헤비제조업자주소`)
                      : t(`ganzi.skin.back.bottom.cn.제조업자주소`)
                    : t(`ganzi.skin.back.bottom.cn.앰플제조업자주소`)}
                </span>
              </div>
              <div className={`${styles.ftTextTr}`}>
                <span className={`${styles.ftText}`}>
                  {t(`ganzi.skin.back.bottom.cn.메이드`)}
                </span>
              </div>
            </div>
          </div>
          <div className={`${styles.footer_right}`}>
            <img
              src={groupInfo === 'LILYARI' ? lilyariLogo : smartSknLogo}
              alt=""
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className={`${styles.footer_content}`}>
          <div className={`${styles.footer_left}`}>
            <img
              className={`${styles.img} ${styles.lillyshopQr}`}
              src={lillyshopQr}
              alt=""
            />
            <p className={`${styles.qrText}`}>
              {t(`ganzi.skin.back.bottom.ko.qr`)}
            </p>
          </div>
          <div className={`${styles.footer_right}`}>
            <img
              src={
                groupInfo === 'ROLE_ADMIN'
                  ? balanxLogo
                  : groupInfo === 'LILYARI'
                    ? lilyariLogo
                    : smartSknLogo
              }
              alt=""
            />
            <div className={`${styles.ftTextTr}`}>
              <span className={`${styles.ftText}`}>
                {t(`ganzi.skin.back.bottom.ko.판매업자`)}{' '}
              </span>
              <span className={`${styles.ftText}`}>
                {t(`ganzi.skin.back.bottom.ko.주소`)}
              </span>
            </div>
            <div className={`${styles.ftTextTr}`}>
              <span className={`${styles.ftText}`}>
                {t(`ganzi.skin.back.bottom.ko.제조업자`)}
              </span>
              <span className={`${styles.ftText}`}>
                {prodType === 'TONER' ||
                prodType === 'LOTION' ||
                prodType === 'ESSENCE'
                  ? prodType === 'LOTION' &&
                    selectedRecipe &&
                    (selectedRecipe[0].baseCode === 'B0005' ||
                      selectedRecipe[0].baseCode === 'B0013')
                    ? t(`ganzi.skin.back.bottom.ko.로션헤비제조업자주소`)
                    : t(`ganzi.skin.back.bottom.ko.제조업자주소`)
                  : t(`ganzi.skin.back.bottom.ko.앰플제조업자주소`)}
              </span>
            </div>
            <div className={`${styles.ftTextTr}`}>
              <span className={`${styles.ftText}`}>
                {t(`ganzi.skin.back.bottom.ko.고객센터`)}
              </span>
              <span className={`${styles.ftText}`}>
                {t(`ganzi.skin.back.bottom.ko.고객센터번호`)}
              </span>
            </div>
            <div className={`${styles.ftTextTr}`}>
              <span className={`${styles.ftText}`}>
                {t(`ganzi.skin.back.bottom.ko.공정거래위원회고시`)}
              </span>
            </div>
            <div className={`${styles.ftTextTr}`}>
              <span className={`${styles.ftText}`}>
                {groupInfo === 'ROLE_ADMIN'
                  ? t(`ganzi.skin.back.bottom.ko.릴리샵`)
                  : ''}
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.padding}>
        {/* 진단코드, 제조번호, 제조일자, 사용기한 */}
        <div className={styles.top}>
          <div>
            <span className={`${styles.fwmd} ${styles.fslg}`}>
              {t('ganzi.skin.back.header.진단코드')}
            </span>
            {' : '}
            <span className={`${styles.fwmd} ${styles.fslg}`}>{skincode}</span>
          </div>
          <div>
            <span className={`${styles.fwmd} ${styles.fslg}`}>
              {t('ganzi.skin.back.header.제조번호')}
            </span>
            {' : '}
            <span className={`${styles.fwmd} ${styles.fslg}`}>
              {createManufacturingNumber(regDt)}
            </span>
          </div>
          <div>
            <span className={`${styles.fwmd} ${styles.fslg}`}>
              {t('ganzi.skin.back.header.제조일자')}
            </span>
            {' : '}
            <span className={`${styles.fwmd} ${styles.fslg}`}>
              {produceDate}
            </span>
          </div>
          <div>
            <span className={`${styles.fwmd} ${styles.fslg}`}>
              {t('ganzi.skin.back.header.사용기한')}
            </span>
            {' : '}
            <span className={`${styles.fwmd} ${styles.fslg}`}>
              {t('ganzi.skin.back.header.개봉일로부터 6개월')}
            </span>
          </div>
        </div>
        {/* mbti */}
        <div className={styles.middle_top}>
          <div className={styles.relative}>
            {/* circle */}
            <div className={`${styles.circle}`}>
              <img
                className={`${styles.img}`}
                src={
                  groupInfo === 'ROLE_ADMIN'
                    ? balanxCircle
                    : groupInfo === 'LILYARI'
                      ? lillyariCircle
                      : balanxCircle
                }
                alt=""
              />
            </div>
            {/* real mbti */}
            <div className={`${styles.mbti}`}>
              <div className={`${styles.mbti_top}`}>{mbtiTopImages}</div>
              <div className={`${styles.mbti_bottom}`}>{mbtiBottomImages}</div>
            </div>
          </div>
        </div>
        {/* mbti summary */}
        <div className={styles.middle_bottom}>
          {/* mbti summary - left */}
          <div className={`${styles.summary_left}`}>
            <div className={styles.smtr}>
              <div
                className={`${styles.smtd_left} ${styles.fwmd} ${styles.fslg}`}
              >
                {t(
                  `ganzi.skin.back.contents.mbti.1.${skinTypeChars && skinTypeChars[0]}`
                )}
              </div>
              <div className={`${styles.smtd_right} ${styles.fslg}`}>
                {t(
                  `ganzi.skin.back.contents.mbti.1.${t(`ganzi.skin.back.contents.mbti.1.${skinTypeChars && skinTypeChars[0]}`)}`
                )}
              </div>
            </div>
            <div className={styles.smtr}>
              <div
                className={`${styles.smtd_left} ${styles.fwmd} ${styles.fslg}`}
              >
                {t(
                  `ganzi.skin.back.contents.mbti.2.${skinTypeChars && skinTypeChars[1]}`
                )}
              </div>
              <div className={`${styles.smtd_right} ${styles.fslg}`}>
                {t(`ganzi.skin.back.contents.mbti.2.민감지수`)}{' '}
                <span className={`${styles.fwlg}`}>
                  {score?.susceptibility}
                </span>
              </div>
            </div>
            <div className={styles.smtr}>
              <div
                className={`${styles.smtd_left} ${styles.fwmd} ${styles.fslg}`}
              >
                {t(
                  `ganzi.skin.back.contents.mbti.3.${skinTypeChars && skinTypeChars[2]}`
                )}
              </div>
              <div className={`${styles.smtd_right} ${styles.fslg}`}>
                {t(`ganzi.skin.back.contents.mbti.3.색소침착지수`)}{' '}
                <span className={`${styles.fwlg}`}>{score?.pigment}</span>
              </div>
            </div>
            <div className={styles.smtr}>
              <div
                className={`${styles.smtd_left} ${styles.fwmd} ${styles.fslg}`}
              >
                {t(
                  `ganzi.skin.back.contents.mbti.4.${skinTypeChars && skinTypeChars[3]}`
                )}
              </div>
              <div className={`${styles.smtd_right} ${styles.fslg}`}>
                {t(`ganzi.skin.back.contents.mbti.4.주름지수`)}{' '}
                <span className={`${styles.fwlg}`}>{score?.wrinkle}</span>
              </div>
            </div>
          </div>
          {/* mbti summary - right */}
          <div className={`${styles.summary_right}`}>
            <span className={` ${styles.fwlg} ${styles.fslg}`}>
              {t(
                `ganzi.skin.back.contents.mbti.result.short.${recipe?.skinType}`
              )}
            </span>
            <p className={` ${styles.fslg}`}>
              {t(
                `ganzi.skin.back.contents.mbti.result.long.${recipe?.skinType}`
              )}
            </p>
          </div>
        </div>
        {/* info */}
        <div className={styles.bottom}>
          <div className={styles.bottom_header}>
            <div>
              <span
                className={`${styles.fwlg} ${styles.fs2xl} ${styles.bottom_title}`}
              >
                {t('ganzi.skin.back.contents.info.title')}
              </span>
            </div>
          </div>
          <div className={styles.bottom_content}>
            <div className={styles.customRecipeTable}>
              <div className={`${styles.tableRow}`}>
                <div className={styles.tableTr}>
                  <span
                    className={`${styles.tdLeft} ${styles.fwlg} ${styles.fslg}`}
                  >
                    {t(`ganzi.skin.back.contents.info.사용방법`)}
                  </span>
                  <span className={`${styles.tdRight} ${styles.fslg}`}>
                    {t(`ganzi.skin.back.contents.info.사용방법Desc`)}
                  </span>
                </div>
              </div>
              <div className={`${styles.tableRow}`}>
                <div className={styles.tableTr}>
                  <span
                    className={`${styles.tdLeft}  ${styles.fwlg} ${styles.fslg}`}
                  >
                    {t(`ganzi.skin.back.contents.info.사용 할 때의 주의사항`)}
                  </span>
                  <span className={`${styles.tdRight} ${styles.fslg}`}>
                    {t(`ganzi.skin.back.contents.info.주의사항Desc`)}
                  </span>
                </div>
              </div>
              {/* 전성분 */}
              <div className={`${styles.allIngredient}`}>
                <span>
                  {t(`ganzi.skin.back.contents.info.전성분.title`)}
                  {': '}
                  {t(
                    `manager.base.${selectedRecipe && selectedRecipe[0].baseName}`
                  )}
                  {': '}
                  {t(
                    `ganzi.skin.back.contents.info.전성분.${prodType}${selectedRecipe && selectedRecipe[0].base}`
                  )}
                  {' ± '}
                  {t(
                    `manager.oil.${selectedRecipe && selectedRecipe[0].oilName}`
                  )}
                  {': '}
                  {t(`ganzi.skin.back.contents.info.전성분.오일`)}
                  {concept1 === '1' && (
                    <span>
                      {' ± '}
                      {t(
                        `manager.concept.${selectedRecipe && selectedRecipe[0].function1Name}`
                      )}
                      {': '}
                      {t(
                        `ganzi.skin.back.contents.info.전성분.${selectedRecipe && selectedRecipe[0].function1Name}`
                      )}
                    </span>
                  )}
                  {concept2 === '1' && (
                    <span>
                      {' ± '}
                      {t(
                        `manager.concept.${selectedRecipe && selectedRecipe[0].function2Name}`
                      )}
                      {': '}
                      {t(
                        `ganzi.skin.back.contents.info.전성분.${selectedRecipe && selectedRecipe[0].function2Name}`
                      )}
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer */}
      <div
        className={`${styles.footer}`}
        style={
          groupInfo === 'ROLE_ADMIN' ? { height: '250px' } : { height: '180px' }
        }
      >
        {renderFooterContent()}
      </div>
    </div>
  );
}
