import {
  Group,
  Button,
  Divider,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  rem,
  Flex,
  Image,
  Tabs,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import balanxLogo from '../../assets/images/BALANX_logo.svg';
import lilyariLogo from '../../assets/images/LILYARI_logo.png';
import smartSknLogo from '../../assets/images/SMARTSKN_logo.png';

import classes from './Header.module.css';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import i18n from '../../assets/locales/i18n';

interface LanguageStateProps {
  languageState: string | null;
  setLanguageState: (value: string | null) => void;
  groupInfo: string;
}

export function Header({
  languageState,
  setLanguageState,
  groupInfo,
}: LanguageStateProps) {
  const navigate = useNavigate();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [cookies, setCookie, removeCookie] = useCookies(['jwt']);
  const [currentLanguages, setCurrentLanguages] =
    useState<{ code: string; label: string }[]>();
  const { t } = useTranslation();

  // 로그아웃 로직
  const deleteCookie = () => {
    removeCookie('jwt');
    navigate('/login');
    // setTimeout(() => navigate('/login'), 2000);
  };

  // 다국어 설정
  const handleLanguageChange = (
    event: React.MouseEvent<HTMLAnchorElement>,
    newLanguage: string
  ) => {
    event.preventDefault();
    setLanguageState(newLanguage);
  };

  useEffect(() => {
    if (groupInfo === 'LILYARI') {
      setCurrentLanguages([
        { code: 'cn', label: 'CN' },
        { code: 'en', label: 'EN' },
      ]);
    } else if (groupInfo === 'SMARTSKN') {
      setCurrentLanguages([
        { code: 'en', label: 'EN' },
        { code: 'es', label: 'ES' },
        { code: 'fr', label: 'FR' },
      ]);
    } else {
      setCurrentLanguages([
        { code: 'ko', label: 'KO' },
        { code: 'en', label: 'EN' },
      ]);
    }
  }, [groupInfo]);

  // 언어 변경하기
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLanguageState(lang);
  };

  return (
    <Box pt={20} pb={40}>
      <header className={classes.header}>
        <Flex justify="space-between" h="100%" align="center">
          {/* <MantineLogo size={30} /> */}
          <Image
            height={20}
            src={
              groupInfo === 'LILYARI'
                ? lilyariLogo
                : groupInfo === 'SMARTSKN'
                  ? smartSknLogo
                  : balanxLogo
            }
            style={{ color: 'black' }}
          />

          <Group h="100%" gap={0} visibleFrom="sm">
            <Tabs
              color="indigo"
              orientation="horizontal"
              defaultValue={languageState}
              onChange={(languageState: string | null) =>
                changeLanguage(languageState!)
              }
              activateTabWithKeyboard={false}
            >
              <Tabs.List grow>
                {currentLanguages?.map((language) => (
                  <Tabs.Tab value={language.code} key={language.code}>
                    {language.label}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
            </Tabs>
          </Group>

          <Group visibleFrom="sm">
            <Button variant="default" onClick={deleteCookie}>
              {t(`logout`)}
            </Button>
          </Group>

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            hiddenFrom="sm"
          />
        </Flex>
      </header>

      {/* 모바일 반응형 햄버거 메뉴 */}
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Manager Page"
        hiddenFrom="sm"
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <Divider my="sm" />
          <Tabs
            color="indigo"
            orientation="vertical"
            defaultValue={languageState}
            // onChange={setLanguageState}
            onChange={(languageState: string | null) =>
              changeLanguage(languageState!)
            }
            placement="left"
            activateTabWithKeyboard={false}
          >
            <Tabs.List>
              {currentLanguages?.map((language) => (
                <Tabs.Tab value={language.code} key={language.code}>
                  {language.label}
                </Tabs.Tab>
              ))}
            </Tabs.List>

            {currentLanguages?.map((language) => (
              <Tabs.Panel style={{ paddingLeft: '16px' }} value={language.code}>
                {t(`nowLang`)} '{language.label}'
              </Tabs.Panel>
            ))}
          </Tabs>
          <Divider my="sm" />

          <Group justify="center" grow pb="xl" px="md">
            <Button variant="default">Logout</Button>
            {/* <Button>Logout</Button> */}
          </Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
