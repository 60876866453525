const base = 'https://popupdiagapp-api.lillycover.com';

interface SendParams {
  method: 'GET' | 'POST' | 'DELETE' | 'PUT';
  path: string;
  data?: any;
  token?: string;
  locale?: string;
}

async function send<T>({
  method,
  path,
  data,
  token,
  locale,
}: SendParams): Promise<T> {
  const headers = new Headers();

  if (data) {
    headers.append('Content-Type', 'application/json');
  }

  if (token) {
    headers.append('x-auth-token', token);
  }

  if (locale) {
    headers.append('locale', locale);
  }

  const response = await fetch(
    path.includes('lillycover.com') ? path : `${base}/${path}`,
    {
      method,
      headers,
      body: data ? JSON.stringify(data) : null,
    }
  );

  const json = await response.text();
  console.log(response);

  try {
    return JSON.parse(json);
  } catch (err) {
    throw new Error(`Failed to parse JSON response: ${err}`);
  }
}

export function get<T>(path: string, token?: string): Promise<T> {
  return send<T>({ method: 'GET', path, token });
}

export function del<T>(path: string, token?: string): Promise<T> {
  return send<T>({ method: 'DELETE', path, token });
}

export function post<T>(
  path: string,
  data?: any,
  token?: string,
  locale?: string
): Promise<T> {
  return send<T>({ method: 'POST', path, data, token, locale });
}

export function put<T>(path: string, data?: any, token?: string): Promise<T> {
  return send<T>({ method: 'PUT', path, data, token });
}
