import { post } from './api';

export const getListApi = async (
  recordState: string | null,
  diagTypeState: string,
  payload: {
    group: string;
    startDt: string;
    endDt: string;
    keyword: string | null;
  },
  token: string
) => {
  const diagnosisOrKiosk = recordState === 'diagnosis' ? 'diagnosis' : 'kiosk';
  const listType =
    diagnosisOrKiosk === 'diagnosis'
      ? diagTypeState === 'skin'
        ? 'getlist'
        : 'getScalpDiaglist'
      : diagTypeState === 'skin'
        ? 'getlist'
        : 'getScalpList';
  const path = `${diagnosisOrKiosk}/${listType}`;

  try {
    const response = await post(path, payload, token);
    console.log(payload);
    console.log(path);

    return response;
  } catch (error) {
    console.error('API 호출 중 에러가 발생했습니다:', error);
  }
};

// if (
//   groupInfo &&
//   formattedPeriodState[0] &&
//   formattedPeriodState[1] &&
//   cookies.jwt
// ) {
//   callApi();
// }
