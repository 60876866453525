import { Container, Title, Text, Button, Group } from '@mantine/core';
import { ErrorIllustration } from './ErrorIllustration';
import classes from './ErrorPage.module.css';
import { useTranslation } from 'react-i18next';

export function ErrorPage() {
  const { t } = useTranslation();
  return (
    <Container className={classes.root}>
      <div className={classes.inner}>
        <ErrorIllustration className={classes.image} />
        <div className={classes.content}>
          <Title className={classes.title}>{t(`error.title`)}</Title>
          <Text
            c="dimmed"
            size="lg"
            ta="center"
            className={classes.description}
          >
            {t(`error.info1`)}
            <br />
            {t(`error.info2`)}
          </Text>
          <Group justify="center">
            <Button size="md">{t(`error.goto`)}</Button>
          </Group>
        </div>
      </div>
    </Container>
  );
}
