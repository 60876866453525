import { useState } from 'react';
import { SegmentedControl } from '@mantine/core';
import classes from './DiagTypeTabs.module.css';

import { useTranslation } from 'react-i18next';
import i18n from '../../../assets/locales/i18n';

interface DiagTypeTabsProps {
  diagTypeState: string;
  setDiagTypeState: (state: string) => void;
}

const DiagTypeTabs = ({
  diagTypeState,
  setDiagTypeState,
}: DiagTypeTabsProps) => {
  const { t } = useTranslation();
  return (
    <SegmentedControl
      value={diagTypeState}
      onChange={setDiagTypeState}
      data={[
        { label: t(`diagType.skin`), value: 'skin' },
        { label: t(`diagType.scalp`), value: 'scalp' },
      ]}
      className={classes.tab}
    />
  );
};

export default DiagTypeTabs;
